.App {
  text-align: center;
}
body {
  overflow-x: hidden;
  
}

.footer-logo-img{
  width:40%;
  height:50px;
}

.excellentleft{
  margin-left:30px;
}

.slider-img{
  height:500px;
  object-fit: cover;
}

.carousel-inner1 img {
  width: 100%;
  height: 600px !important;
  border-radius: 13px;
}

.collage-img{
width: 200px;
height: 200px;

}


.btn-orange-map{
  background-color:orange;
  color:white;
  border:1px solid orange;
  border-radius:5px;
  padding:5px 10px;
  justify-content:'center';

}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* NavLinks Styling */

.logo-img{
  /* margin-top: -15px; */
  width:33%;
  height:50px;
}

.nav-link1{
  color:black;
  font-weight: bold;
  padding-right:30px;
  
}

.nav-link1:hover{
  text-decoration: none;
  color:orange;
}

.btn-book{
  border: 2px solid orange;
  color:black;
  padding: 7px 30px;
  color: orange;
  font-weight: 700;
  border-radius: 5px;
}

.btn-book:hover{
color:white;
background-color: orange;
text-decoration: none;
}

/* Home Page Styling */

.explore-btn{
  background-color: orange;
  padding: 8px 60px;
  color:white;
border-radius: 5px;
  border: none;
}

.home-container{
  background-image: url('/public/images/home-slider2.jpg');
  border-radius: 13px;
  color:white;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 250px;
  padding-bottom: 100px;
  opacity: 1;
}

.search-engine{
  background-color: white;
  padding: 20px;
  width:60%;
  border-radius: 10px;
  text-align: left;
  color: black !important;
}

.search-btn{
  background-color: orange;
  color:white;
  border-radius: 50%;
  padding: 10px;
  font-size: 50px !important;
  align-items: center;
  margin-top: 5px;
}

.no-border{
  background-color: orange;
  color:white;
  border-radius: 50%;
  border: none;
  padding: 8px;
  align-items: center;
  margin-top: 8px;
  text-align: center;
}

.search-label{
  font-weight: bold;
}

input{
  border:none;
  width: auto;
}
input:focus{
  outline: none;
}


/* Cards Section */
.card{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 13px !important;
}

.card > img{
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  height: 200px;
  object-fit: cover;
}


/* Recommended Stays Section */
.section-heading{
  font-weight: 700;
}

.destination-img >img{
  height: 350px;
}



  .carousel-inner img {
    width: 100%;
    height: 280px;
    border-radius: 13px;
    object-fit: cover !important;
  }



  #demo:hover{
    color: white;
    border: 1px solid white;
    border-radius: 13px;
    transform: scale(1.02);
    transition: transform .20s ease;
  
  }
  #demo1:hover{
    color: white;
    border: 1px solid white;
    border-radius: 13px;
    transform: scale(1.02);
    transition: transform .20s ease;
  
  }
  #demo2:hover{
    color: white;
    border: 1px solid white;
    border-radius: 13px;
    transform: scale(1.02);
    transition: transform .20s ease;
  
  }
  #demo3:hover{
    color: white;
    border: 1px solid white;
    border-radius: 13px;
    transform: scale(1.02);
    transition: transform .20s ease;
  
  }


  .carousel-inner img:hover img{
    border-radius: 13px !important;
  }

  /* .carousel-control-prev-icon{
    opacity: 0;
   
  }

  .carousel-control-prev-icon:hover{
    opacity: 1;
  } */

  .carousel .carousel-indicators li {
    width: 10px;
   height: 10px;
   border-radius: 100%;
 }

 .stays{
   font-weight: bold;
   font-size: 14px;
   
 }

 .price{
   font-size: 10px;
   font-weight: 600;
   color: gray;
 }
 .address{
   font-weight: bold;
   color: gray;
   font-size: 14px;
 }

 .destination-img{
   width:100%;
   object-fit: cover;
   height:300px;
   border-radius: 13px;
 }
 .destination-img1{
  width:100%;
  object-fit:cover ;
  height:300px;
  border-radius: 13px;
}

 .caption{
   /* color:white !important; */
   display: inline;
   font-weight: bold;
   font-size: 20px;

 }

 .bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
  display: inline;
  font-weight: bold;
  font-size: 20px;
  color:white;
  padding-left:8px;
}



/* Host Section */

.host{

  padding-left: 0px !important;

}
.host > img{
  width:100%;
  height:460px;
}

.hosting-section{
  background-color: black;
  margin-left: auto;
  margin-right: auto;
}

.col-padding{

}

.center{
  padding: 130px 0;
  text-align: center;
  color:grey;
}

.container-fluid{
  margin: 0px !important;
  padding: 0px !important;
}

/* Footer */

.footer{
  background-color: #f5f5f5;
}

.footer-logo > img{
  height:50px;
}

ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
li{
  text-decoration: none;
}

.collage{
  margin-top: -100px;
  background-color: black;
  border-radius: 50%;
  height: 200px;
  padding: 50px;
  z-index: 1;
}
.second-row{

margin-top: -100px;
}

.footer-links{
  color: black;
}
.footer-links:hover{
  color:orange;
  text-decoration: none;
}
.footer-links1{
  color: black;
}
.footer-links1:hover{
  color:orange;
  cursor:context-menu;
  text-decoration: none;

}
.social-icon{
  color: white;
  border-radius: 50% !important;
  border: 1px solid orange;
  background-color: orange;
  text-align: center;
  justify-content: center;
  align-items: center;
 padding: 2px;
 margin-right: 10px;
}

.ul{
  list-style-type: disc !important;
}

.li{
  display: inline;
  margin-left: 20px;
  color: gray;
}
.dot{
  font-size: 54px;
  line-height: 16px;
  margin-right: 10px;
}

.cards{
  font-size: 22px;
  margin-right: 10px;
 color:orange;
  padding: 3px;
  
}

.cards1{
  font-size: 22px;
  margin-right: 10px;
 color:orange;
  padding: 3px;
  
}


/* Listing Page */
.btn-light{
  background-color: white !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  
}

.btn-image{
  background-image: url('/public/images/map.jpg');
  padding:30px 25%;
  border-radius: 10px;
}

.flex-container {
  display: flex;
  justify-content: space-between;

}

.checkbox{
  font-size: 40px !important;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ffffff;
  border-radius: 5px;
  border: 2px solid lightgray;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  /* background-color: #ccc; */
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.span-class{
  margin-left: 20px;
}

.img{
  height:100px !important;
}

.carousel-item1 img{
  height:207px !important;
}

.rating{
  background-color: orange;
  text-align: center;
  padding: 4px 4px;
  border-radius: 5px;
  width:50px;
  height:40px;
  margin-right: 30px !important;
}

.row-margin{
  padding-right: 0px;
  margin-right: 0px;
  margin-top: 15px;
}

.heart{
  /* font-size: 35px; */
  color:rgb(182, 173, 173);
  margin-top: 7px;
   width: 70px;
   outline: none;
   text-transform: uppercase;
   cursor: pointer;
   font-weight: bold;
}
  .active {
   color: red;
  }


  /* OPen map Page */
  .flex-direction{
    display: inline;
  }

  .container1 {
    display: inline;
    position: relative;
    padding-left: 10px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right:15px;
  }


  .icon-styling{
    color: lightgray;
    border: 2px solid lightgray;
    padding: 2px;
    border-radius: 5px;
    font-size: 38px !important;
    
  }


  .room-image1{
    height:150px !important;
    margin-bottom: 15px;
    object-fit: cover;
  }
  .room-image2{
    height:150px !important;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    object-fit: cover;
  }

  .big-image{
    height: 315px;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
    object-fit: cover;
  }

  .rest-border{
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 8px;
  }

  .iframe-map{
    border: none;
    border-radius: 13px;
    width: 10%;
  }

  .iframe-map-small{
    border-radius: 13px;
    border: 1px solid lightgray;
  }


  .profilepic{
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .rating-icon{
    color: gold;
  }

  .row-margin1{
    margin-right: -15px !important;
  }
  
  .guest{
    background-color: transparent;
  
  }

  .search-col{
    padding-right: 10px !important;
    margin-right: -20px !important;
    
  }

  .show-map{
    font-size: 12px;
  }

  .date{
    width:140px;
  }

  .guest{
    width: 140px;
  }


  /* Login  */
  .signIn-btn{
    background-color: orange;
    padding: 8px 60px;
    color:white;
  border-radius: 5px;
    border: none;
  }
  
  .applyNow-btn{
    border: 2px solid orange;
    background-color: transparent;
    color:black;
    padding: 7px 30px;
    color: orange;
    font-weight: 700;
    border-radius: 5px;
  }
  
  .applyNow-btn:hover{
  color:white;
  background-color: orange;
  text-decoration: none;
  }


  .location-dropdown{
    border:none;
    width:100%;
  }
 .location-dropdown:focus{
    outline: none;
  }

  .profile-pic{
    width:200px;
    height: 200px;
    border-radius: 50%;
  }


  .upload-new-dp{
    border: 2px solid orange;
    background-color: transparent;
    color:black;
    padding: 7px 30px;
    color: orange;
    font-weight: 700;
    border-radius: 5px;
  }
  
  .upload-new-dp:hover{
  color:white;
  background-color: orange;
  text-decoration: none;
  }


  .update-profile-btn{
    background-color: orange;
    padding: 8px 30px;
    color:white;
  border-radius: 5px;
    border: none;
  }

  .orange-background{
    background-color: orange;
    color:white;
    padding-top: 5px;
  }



  .checkbox{
    width:20px;
    height: 20px;
    margin-left: 10px;
    margin-right: 5px;
    margin-top: 10px !important;
  }

  .orangeLoftImage{
    width: 100%;
  }

  .carousel-inner-img{
    object-fit: cover !important;
  }


  .dest-image img{


  }

  .sticky{
    position: sticky;
    top: 0;
  }
.textDecoration{
  text-decoration: none !important;
}
  .textDecoration:hover{
    text-decoration: none !important;
  }

  .markerStyling{
    height:20px;
  }

  .hoverImage{
    width:100%;
    height:80px;
  }

  .alert-color-success{
    background-color: green;
    opacity:0.3;
    padding:10px 20px;
    border-radius:5px;
  }

  .iframe-style{
    width:100%;
    height:100vh;
    border:none;
    margin-top:-15px;
  }

  .text-dark{
    text-decoration: none;
  }

  .text-dark:hover{
    text-decoration:none;
  }
  .textright{
    
    text-align: right;
  
  }
  .text-alignment{
    text-align: right ;
  }

  @media screen and (max-width: 980px) {
  

    .mobilenav {
      display: block !important;
      /* width: 40%  !important; */
    
     
    }
    .mobilenav1{
      display: block !important;
      margin-right: 28px !important;

    }

    .mobilenav2{
      display: block !important;
      margin-left: 38px !important;

    }
    .logo-img{
      width:48%;
      height:40px;
    }
    .footer-logo-img{
      width:30%;
      height:52px;
    }
.book-now-btn-hide{
  display:none;
}

.text-alignment{
  text-align: left ;
}
.heart{
  display:none;
}
.excellentleft{
  background-color: 'red';
  margin-left:-20px;
}


    .footer-socialicons{
      margin-left: 35% !important;
      display: inline-block !important;


    }
    .textright{
    
      text-align: left !important;
      margin-left: 15px !important;
      
    
    }
    .excellentleft{
     
      margin-left: 5px !important;

    }
  }

  .booking-btn{
    background-color: orange;
    color:white;
    border-radius:5px;
    border: none;
    padding:5px 15px;
    margin-top: 10px;
  }

  .nav-link2:hover{
    color: orange;
  }

  .text-orange{
    color:orange;
  }

  .policy-text{
    font-size:13px;
    margin-top: 5px;
  }

  .btn-orange{
    background-color:orange;
    color:white;
    border:1px solid orange;
    border-radius:5px;
    padding:5px 15px;

  }

  .btn-image1{
    background-image: url('/public/images/map.png');
    padding:90px 20%;
    border-radius: 10px;
    /* height:200px; */
    object-fit:fill;
   
  }

  .modal-map{
    width:99.7%;
    height:400px;
    padding:0px;
    border: none;
    object-fit: cover;
    
  }
  
  .modal-body{
    padding:0px !important;
    border-radius: 5px;
  }

  .book-now-img{
    border-top-left-radius: 13px;
  }

  .link{
    color:black;
  }

  .link:hover{
    text-decoration:none;
    color:orange;
  }

  .blog-img1{
    width:100%;
    height:550px;
    object-fit: cover;
    object-position: 80% 100%;
  }
  .blog-img2{
    width:100%;
    height:550px;
    object-fit: cover;
  }

  .card-img-top{
    height:200px;
    object-fit: cover;
    object-position: 10% 100%;
    border-top-left-radius: 13px !important;
    border-top-right-radius: 13px !important;
  }
  .card-img-top1{
    object-fit: cover;
  object-position: 15% 100%;
    width:100%;
    height:200px;
    border-top-left-radius: 13px !important;
    border-top-right-radius: 13px !important;
  }

  .spinner-color{
    color:orange;
  }

.style1{
  text-align: center !important;
}

@media (min-width: 680px) {
  .Search-btn{
    display:none;
  }
  .mob-room-details-section{
    display:none !important;
  }

}



@media (max-width: 680px) {
  .big-image{
    height:200px;
    border-radius: 20px;
    margin-bottom:10px;
  }

  .room-image1{
    height:200px !important;
    border-radius: 20px;
    margin-bottom: 10px;
    
    
  }
  .room-image2{
    height:200px !important ;
    border-radius: 20px;
    margin-bottom: 10px;
  }
  .btn-orange-map{
    background-color:orange;
    color:white;
    border:1px solid orange;
    border-radius:5px;
    padding:5px 10px;
    justify-content:'center';

  }
  .btn-image1{
    background-image: url('/public/images/map.png');
    align-items: center !important;
    justify-content: center !important;
    border-radius: 10px;
    padding:35%;
    /* height:200px; */
    object-fit:fill;
   
  }

  .btn-image{
    align-items: center !important;
    justify-content: center !important;
    border-radius: 10px;
    padding:35%;
    /* height:200px; */
    object-fit:fill;
  }
  .logo-img{
    /* margin-top: -15px; */
    width:52%;
    height:40px;
  }
  .date{
    width:100%;
  }
  .no-border{
   display:none;
  }
  .Search-btn{
    background-color: orange;
    text-align: center;
    width:80%;
    border-radius:5px;
    border:none;
    color:white;
    padding:5px;
  }
  .guest{
    width:100%;
  }
.icon-styling{
    display:none !important;
  }

  .booking-btn{
    float: left;
    margin: 10px;
  }

  .carousel-inner{
    padding-right: 15px;
  }

  .book-now-img{
    padding:10px !important;
    border-radius:17px !important;
    height:250px !important;
  }
  .mob-padding{
    margin:30px !important;
  }

  .mob-hide-info{
    display:none !important;
  }

  .mob-p{
    color: black;
  }
  .collage{
    display: none;
  }
  
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
}

